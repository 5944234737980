<template>
  <div class=" container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between">
        <div class="text-h4">Roles & Permissions</div>
      </div>

      <div class="row q-gutter-xl">
        <q-btn @click="createRole = true" color="primary">Add New Role</q-btn>
        <q-btn @click="showEditRole = true" color="primary">Edit Existing Role</q-btn>
      </div>

      <div v-if="showEditRole">
        <div class="row q-mt-xl block">
          <div class="text-h6">Select a Role to Edit</div>
        </div>
        <div class="row gutter-xs q-mt-sm">
          <div class="col-6">
            <q-select emit-value map-options clearable label="Select Role" id="roleEdit" @input="selectRole" v-model="editRole" :options="roles" :loading="loadingRoles" popup-content-class="bg-lmsBackground primaryText-text">
              <template v-slot:no-option>
                <div v-if="loadingRoles" class="q-pa-md text-body2 text-italic">Loading Custom Roles...</div>
                <div v-else class="q-pa-md text-body2 text-italic">No Custom Roles to Edit</div>
              </template>
            </q-select>
          </div>
        </div>
      </div>

      <div class="row q-my-xl" v-if="editRole">
        <div class="col-6">
          <q-card flat class="bg-lmsBackground">
            <q-form @submit="updateRole" @reset="editRole = ''" autocomplete="off">
              <q-card-section>
                <div class="text-h6">Edit {{ roleToEdit.label }}</div>
              </q-card-section>
              <q-card-section class="col-6">
                <div class="q-mb-lg">
                  <q-input v-model.trim="roleToEdit.label" label="Name" :rules="formRules.name" />
                </div>
                <div class="q-mb-lg">
                    <filter-select-multiple :options="permissions" v-bind:selection.sync="roleToEdit.permissions" label="Permissions" :rules="formRules.permissions" emit-value />
                </div>
              </q-card-section>
              <div class="row gutter-xs q-mt-lg">
                <div class="col-12">
                  <q-btn @click="deleteRole()" flat class="button-custom-color margin-top-1-3">Delete</q-btn>
                  <q-btn type="submit" class="float-right" color="primary">Update</q-btn>
                  <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
                </div>
              </div>
              <q-inner-loading :showing="editWorking">
                <q-spinner size="50px"/>
              </q-inner-loading>
            </q-form>
          </q-card>
        </div>
      </div>

      <q-dialog v-model="createRole" @hide="resetNew">
        <q-card style="width: 560px; max-height: 1000px;" class="bg-lmsBackground primaryText-text">
          <q-form @submit="addRole" @reset="createRole = false" autocomplete="off">
            <q-card-section>
              <div class="text-h5">Create New Role</div>
            </q-card-section>
            <q-card-section>
              <div class="q-my-md">
                <q-input label="Name" v-model.trim="newRole.name" :rules="formRules.name" />
                <div class="form-error-msg hidden text-negative">This field requires at least 3 characters.</div>
              </div>
              <div class="q-mt-xl">
                <filter-select-multiple :options="permissions" v-bind:selection.sync="newRole.permissions" label="Permissions" emit-value />
              </div>
            </q-card-section>
            <div class="row gutter-xs q-my-lg q-mx-lg">
              <div class="col-12">
                <q-btn type="submit" class="float-right" :loading="addingRole" color="primary">Add</q-btn>
                <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import filterSelectMultiple from 'components/filterSelectMultiple'

export default {
  components: { filterSelectMultiple },
  data () {
    return {
      showEditRole: false,
      editWorking: false,
      createRole: false,
      addingRole: false,
      roleToEdit: {},
      roles: [],
      permissions: [],
      editRole: null,
      newRole: {
        name: null,
        description: null,
        is_inactive: false,
        users: [],
        permissions: []
      },
      loadingRoles: false
    }
  },
  computed: {
    formRules: function () {
      return {
        name: [ this.$formRules.required('Role Name') ]
      }
    }
  },
  methods: {
    getPermissions () {
      this.permissions = []
      this.$store.dispatch('auth/getPermissions').then((resp) => {
        _.forEach(resp, (perm, index) => {
          if (perm.slug !== 'manage-bug-requests') {
            this.permissions.push({
              value: perm.uid,
              label: perm.name
            })
          }
        })
        this.permissions = _.sortBy(this.permissions, ['name'])
      })
    },
    getRoles () {
      this.loadingRoles = true
      this.roles = []
      this.$store.dispatch('auth/getRoles').then((resp) => {
        _.forEach(resp, (role, index) => {
          if (role.slug !== 'master-admin' && role.slug !== 'site-admin' && role.slug !== 'location-admin' && role.slug !== 'default') { // don't show the master admin role unless it's a user that is a master admin
            this.roles.push({
              value: role.uid,
              label: role.name,
              permissions: role.permissions.map(perm => perm.uid),
              users: role.users_count
            })
          }
        })
        this.loadingRoles = false
      })
    },
    deleteRole () {
      this.editWorking = true
      this.$store.dispatch('auth/deleteRole', this.roleToEdit.value).then((resp) => {
        this.$successMsg()
        this.getRoles()
        this.editWorking = false
        this.editRole = ''
        this.roleToEdit = {}
      }).catch(err => {
        this.$log(this.$options.name, 'Cannot delete role', err)
        this.$failureMsg()
        this.editWorking = false
      })
    },
    addRole () {
      this.addingRole = true
      if ((_.lowerCase(this.newRole.name) === 'master-admin' || _.lowerCase(this.newRole.name) === 'master admin') ||
      (_.lowerCase(this.newRole.name) === 'site-admin' || _.lowerCase(this.newRole.name) === 'site admin') ||
      (_.lowerCase(this.newRole.name) === 'location-admin' || _.lowerCase(this.newRole.name) === 'location admin') ||
      (_.lowerCase(this.newRole.name) === 'default' || _.lowerCase(this.newRole.name) === 'default')) {
        this.addingRole = false
        this.$failedValidationMsg('Role name is a reserved phrase please enter a different name.')
        return
      }

      let tRole = this.newRole
      tRole.description = tRole.name
      tRole.is_inactive = false

      this.$store.dispatch('auth/addRole', tRole).then((resp) => {
        this.$successMsg()
        this.addingRole = false
        this.createRole = false
        this.getRoles()
      }).catch((err) => {
        this.$failureMsg()
        this.$log(this.$options.name, 'Saving Role Error', err)
        this.addingRole = false
      })
    },
    updateRole () {
      this.editWorking = true

      let tRole = this.roleToEdit
      tRole.name = this.roleToEdit.label
      tRole.description = tRole.name
      tRole.is_inactive = false
      tRole.uid = this.editRole

      this.$store.dispatch('auth/updateRole', tRole).then(() => {
        this.editWorking = false
        this.$successMsg()
        this.getRoles()
        this.roleToEdit = {}
        this.editRole = ''
      }).catch((e) => {
        this.$failureMsg()
        this.$log(this.$options.name, 'Saving Role Error', e)
        this.editWorking = false
      })
    },
    selectRole () {
      this.roleToEdit = this.roles.find(role => role.value === this.editRole)
    },
    resetNew () {
      this.newRole = {
        name: null,
        description: null,
        is_inactive: false,
        users: [],
        permissions: []
      }
    }
  },
  created () {
    this.getRoles()
    this.getPermissions()
  }
}
</script>
